<template>
    <v-form ref="form">
        <v-card style="padding: 20px;">
            <v-card-title>
                <label class="display-1 font-weight-bold">Данные покупателя</label>
                <v-spacer/>
                <v-btn icon large @click="$emit('close')">
                    <v-icon large>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <v-layout style="margin-top: 20px;">
                    <label class="subtitle-1 label">Номер телефона</label>
                    <v-fade-transition mode="out-in">
                        <template v-if="isEditPhone">
                            <v-layout column key="edit">
                                <v-layout>
                                    <v-select :items="countryItems"
                                              color="secondary"
                                              item-value="idCountry"
                                              :loading="countriesLoading"
                                              hide-details
                                              item-color="secondary"
                                              :disabled="phoneLoading"
                                              v-model="selectedCountry"
                                              style="max-width: 100px; margin-top: -15px;">
                                        <template v-slot:item=" { item } ">
                                            <label class="subtitle-1">{{item.countryFlag}}</label>
                                            <label class="subtitle-1" style="margin-left: 15px;">{{item.title}}</label>
                                            <label class="subtitle-2 grey--text"
                                                   style="margin-left: 5px; margin-top: 4px;">{{item.phonePrefix}}</label>
                                        </template>

                                        <template v-slot:selection=" { item } ">
                                            <label class="subtitle-1">{{item.countryFlag}}</label>
                                            <label class="subtitle-1"
                                                   style="margin-left: 10px;">{{item.phonePrefix}}</label>
                                        </template>
                                    </v-select>

                                    <v-layout column>
                                        <the-mask v-model="curItem.phone"
                                                  class="subtitle-1 font-weight-regular black--text phone-input"
                                                  @input="phoneTaken = false"
                                                  :disabled="phoneLoading"
                                                  :mask="phoneMask"/>
                                        <v-slide-y-transition>
                                            <label class="caption error--text" v-if="phoneTaken">
                                                Пользователь с указанным номером уже есть в базе</label>
                                        </v-slide-y-transition>
                                    </v-layout>

                                </v-layout>
                                <v-btn color="secondary"
                                       @loading="phoneLoading"
                                       @click="savePhone"
                                       :disabled="isSaveButtonDisabled"
                                       style="width: 160px; height: 50px; border-radius: 8px; margin-top: 5px;">
                                    Сохранить
                                </v-btn>
                            </v-layout>
                        </template>

                        <template v-else>
                            <v-layout key="view">
                                <label class="subtitle-1">{{user.phone}}</label>
                                <label class="subtitle-1 font-weight-bold pointer"
                                       @click="openEditPhone"
                                       style="margin-left: 15px;">Изменить</label>
                            </v-layout>
                        </template>
                    </v-fade-transition>
                </v-layout>

                <v-layout style="margin-top: 25px;">
                    <label class="subtitle-1 label">Имя</label>
                    <label class="subtitle-1">{{user.firstName}}</label>
                </v-layout>

                <v-layout style="margin-top: 25px;" v-if="user.email">
                    <label class="subtitle-1 label">Почта</label>
                    <label class="subtitle-1">{{user.email}}</label>
                </v-layout>

                <v-layout style="margin-top: 25px;">
                    <label class="subtitle-1 label">Страна</label>
                    <label class="subtitle-1">{{user.countryTitle}}</label>
                </v-layout>

                <v-layout style="margin-top: 40px;" justify-center align-center column>
                    <label class="subtitle-1" v-if="curItem.isBlocked">Пользователь заблокирован.</label>

                    <div class="subtitle-1 font-weight-bold pointer"
                         v-ripple
                         style="border-radius: 8px; width: 300px; height: 40px;">
                        <v-layout align-center justify-center fill-height @click="blockCustomer">
                            <label class="pointer">
                                {{curItem.isBlocked ? 'Разблокировать' : 'Заблокировать'}} пользователя</label>
                        </v-layout>
                    </div>
                </v-layout>

                <v-card style="margin-top: 40px; padding: 0;"
                        class="elevation-0">
                    <v-overlay v-model="requestsLoading" color="white" absolute>
                        <v-progress-circular indeterminate color="secondary"/>
                    </v-overlay>
                    <v-layout>
                        <label class="subtitle-1">
                            {{totalCount}} {{countTitle}}, {{hiddenCount}} скрыто</label>
                    </v-layout>

                    <v-layout v-if="totalCount"
                              @scroll="handleScroll"
                              column
                              style="max-height: 360px; overflow-y: auto;">
                        <request-card :request="curItem.requests[Object.keys(curItem.requests)[0]]"/>
                        <v-slide-y-transition mode="out-in">
                            <template v-if="!isAllRequests">
                                <v-layout column key="withButton">
                                    <v-btn color="secondary"
                                           @click="isAllRequests = true"
                                           style="width: 230px; height: 45px; margin-top: 30px;">
                                        Показать ещё {{restCount}}...
                                    </v-btn>
                                </v-layout>
                            </template>
                            <template v-else>
                                <v-layout column key="withoutButton">
                                    <request-card v-for="r in Object.values(curItem.requests).slice(1)"
                                                  :request="r"
                                                  :key="r.idOrder"/>
                                </v-layout>
                            </template>
                        </v-slide-y-transition>
                    </v-layout>
                </v-card>
            </v-card-text>

            <v-overlay color="white"
                       opacity="0.5"
                       absolute
                       v-model="loading">
                <v-progress-circular indeterminate color="secondary"/>
            </v-overlay>
        </v-card>
    </v-form>
</template>

<script>
    import {TheMask} from 'vue-the-mask'

    import ValidationMixin from "../../mixins/ValidationMixin";
    import RequestCard from "./RequestCard";

    export default {
        components: {RequestCard, TheMask},
        name: "UserForm",
        mixins: [ValidationMixin],
        props: {
            user: {
                type: Object,
                required: true
            },
            isVisibleEditModal: {
                type: Boolean,
                required: true
            },
            editLoading: {
                type: Boolean,
                required: true
            }
        },
        data: () => ({
            curItem: {details: {}, location: {}, car: {}},
            loading: false,
            requestsLoading: false,
            isAllRequests: false,
            isEditPhone: false,
            page: 1,
            limit: 5,
            totalCount: 0,
            hiddenCount: 0,
            phoneMask: "",
            selectedCountry: null,
            countriesLoading: false,
            phoneLoading: false,
            phoneTaken: false
        }),
        computed: {
            restCount() {
                return this.totalCount > 1 ? this.totalCount - 1 : 0
            },
            countryItems() {
                return this.$store.state.customerCountryItems
            },
            countTitles() {
                return ['запрос', 'запросов', 'запроса']
            },
            countTitle() {
                const count = this.totalCount;
                let index = 0;
                if (count < 10 || count > 20) {
                    index = (count % 10 === 1) ? 0 : (count % 10 > 4 || count % 10 === 0) ? 1 : 2;
                    return this.countTitles[index]
                } else return 'запросов'
            },
            isSaveButtonDisabled() {
                let country = this.countryItems.filter(v => v.idCountry === this.selectedCountry)[0];
                console.log(this.unmaskValue(this.curItem.phone).length !== this.unmaskValue(country.phoneMask).length);
                return this.unmaskValue(this.curItem.phone).length !== this.unmaskValue(country.phoneMask).length
            }
        },
        watch: {
            isVisibleEditModal(to) {
                if (this.$refs.form)
                    this.$refs.form.resetValidation();
                this.page = 1;
                if (to) {
                    this.getFullInfo();
                    this.curItem = {...this.user, requests: {details: {}, location: {}, car: {}}};
                }
                this.isEditPhone = false;
                this.isAllRequests = false;
            },
            selectedCountry(to) {
                this.phoneTaken = false;
                if (to) this.updatePhoneByCountry(to)
            },
            user(to) {
                this.curItem.isBlocked = to.isBlocked;
                this.curItem.phone = to.phone
            },
            isEditPhone(to) {
                if (!to) this.selectedCountry = null
            }
        },
        methods: {
            unmaskValue(text) {
                const defaultDelimiters = /[-!$%^&*()_+|~=`{}[\]:";'<>?,./\\ ]/
                return text ? String(text).replace(new RegExp(defaultDelimiters, 'g'), '') : text
            },
            saveItem() {
                if (this.$refs.form.validate()) {
                    this.$emit('saveItem', this.curItem)
                }
            },
            getFullInfo() {
                this.requestsLoading = true;
                const params = {
                    headers: {token: localStorage.getItem('token')},
                    params: {
                        idCustomer: this.user.idCustomer,
                        page: this.page - 1,
                        limit: this.limit
                    }
                };
                this.$http.get("/customers/orders", params)
                    .then(response => {
                        this.totalCount = response.data.data.count;
                        this.hiddenCount = response.data.data.closedCount;
                        if (Object.keys(response.data.data.orders).length) {
                            for (let v in  response.data.data.orders)
                                this.curItem.requests[v] = response.data.data.orders[v];
                            this.page += 1
                        }
                    })
                    .catch(error => {
                        switch (error.response.status) {
                            case 406:
                                // this.$router.history.push({name: 'login'})
                                break;
                            case 401:
                                this.$router.history.push({name: 'login'});
                                break;
                            default:
                                console.log('error');
                                break;
                        }
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.requestsLoading = false
                        }, 500)
                    })
            },
            getCountries() {
                this.countriesLoading = true;
                const params = {
                    headers: {token: localStorage.getItem('token')},
                    params: {
                        role: 'customer'
                    }
                };
                this.$http.get("/countries", params)
                    .then(response => {
                        this.$store.dispatch('setCustomerCountryItems', response.data.data)
                    })
                    .catch(error => {
                        switch (error.response.status) {
                            case 406:
                                // this.$router.history.push({name: 'login'})
                                break;
                            case 401:
                                this.$router.history.push({name: 'login'});
                                break;
                            default:
                                console.log('error');
                                break;
                        }
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.countriesLoading = false
                        }, 500)
                    })
            },
            handleScroll(event) {
                if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight)
                    this.getFullInfo()
            },
            openEditPhone() {
                this.selectedCountry = this.curItem.idCountry;
                this.isEditPhone = true;
            },
            updatePhoneByCountry(idCountry) {
                let country = this.countryItems.filter(v => v.idCountry === idCountry)[0];
                this.phoneMask = country.phoneMask.replace(/x/g, '#');
                this.$nextTick(() => {
                    this.curItem.phone = this.user.phone.substring(country.phonePrefix.length);
                    console.log(this.phoneMask, this.curItem.phone, this.user.phone, country.phonePrefix.length);
                })
            },
            savePhone() {
                this.phoneLoading = true;
                const params = {
                    headers: {token: localStorage.getItem('token')}
                };

                const postData = {
                    idCustomer: this.user.idCustomer,
                    idCountry: this.selectedCountry,
                    phone: this.unmaskValue(this.curItem.phone)
                };
                console.log(postData)
                this.$http.post("/customers/update", postData, params)
                    .then(response => {
                        this.curItem.phone = response.data.data.phone;
                        this.saveItem();
                        this.isEditPhone = false
                    })
                    .catch(error => {
                        console.log(error)
                        switch (error.response.status) {
                            case 406:
                                // this.$router.history.push({name: 'login'})
                                break;
                            case 401:
                                this.$router.history.push({name: 'login'});
                                break;
                            case 409:
                                this.phoneTaken = true;
                                break;
                            default:
                                console.log('error');
                                break;
                        }
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.phoneLoading = false
                        }, 500)
                    })
            },
            blockCustomer() {
                this.loading = true;
                const params = {
                    headers: {token: localStorage.getItem('token')}
                };

                const postData = {
                    idCustomer: this.user.idCustomer,
                    isBlocked: !this.curItem.isBlocked
                };
                console.log(postData)
                this.$http.post("/customers/status", postData, params)
                    .then(response => {
                        this.curItem.isBlocked = response.data.data.isBlocked;
                        this.saveItem();
                    })
                    .catch(error => {
                        console.log(error)
                        switch (error.response.status) {
                            case 406:
                                // this.$router.history.push({name: 'login'})
                                break;
                            case 401:
                                this.$router.history.push({name: 'login'});
                                break;
                            default:
                                console.log('error');
                                break;
                        }
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.loading = false
                        }, 500)
                    })
            }
        },
        beforeMount() {
            this.curItem = {...this.user, requests: {details: {}, location: {}, car: {}}};
            this.isAllRequests = false;
            this.page = 1;
            this.getFullInfo();

            if (!this.countryItems.length) this.getCountries()
        }
    }
</script>

<style scoped>
    .label {
        width: 180px;
    }

    .pointer {
        color: black;
    }

    .pointer:hover {
        color: grey;
        cursor: pointer;
    }

    .phone-input {
        max-width: 150px;
        padding: 3px;
        margin-left: 10px;
        margin-top: -5px;
        border-bottom: 1px solid grey;
    }
</style>