<template>
    <v-card class="elevation-0"
             v-if="request.car"
            style="min-height: 150px; max-height :500px; border-radius: 18px; border: 1px solid #E0E0E0; padding: 0; margin-top: 10px;">
        <v-layout column
                  fill-height
                  style="padding: 20px 25px 20px 25px; min-height: 150px; ">
            <v-layout column>
                <v-layout v-for="detail in request.details" style="min-height: 30px;" :key="detail.idDetail">
                    <label class="font-weight-regular black--text"
                           style="width: 200px; font-size: 16pt;">
                        {{detail.title}}
                    </label>
                    <label class="font-weight-regular grey--text"
                           style="margin-left: 10px; font-size: 16pt;">
                        {{detail.description || "Без описания"}}
                    </label>
                </v-layout>
            </v-layout>

            <v-layuut style="max-height: 30px;"
                      class="grey--text"
                      v-if="request.idStatus === 2">
                <v-icon>mdi-eye-off</v-icon>
                <label style="margin-left: 5px;" class="subtitle-1">Скрыт</label>
            </v-layuut>

            <v-layout style="min-height: 30px; margin-top: 10px;" align-start>

                <label class="subtitle-1" style="width: 200px;">{{request.createDate | localTime}}</label>
                <v-layout class="subtitle-1" style="max-width: 200px;" align-start>
                    <v-icon>mdi-car</v-icon>
                    <v-layout row wrap style="max-width: 170px; margin-left: 5px;">
                        {{request.car.titleBrand}}, {{request.car.titleModel}}
                        {{addCarDetail('titleGeneration')}}
                        {{addCarDetail('titleSerie')}}
                        {{addCarDetail('titleTrim')}}
                    </v-layout>
                </v-layout>
                <v-layout class="subtitle-1" style="max-width: 200px; margin-left: 15px;" align-start>
                    <v-icon>mdi-web</v-icon>
                    <v-layout row wrap style="max-width: 170px; margin-left: 5px;">
                        <template v-for="l in request.location">
                            <template v-if="l.isAllCountry">
                                All {{l.titleCountry}} <br>
                            </template>

                            <template v-else-if="Object.keys(l.cities).length > maxCitiesCount">
                                <template v-for="c in Object.values(l.cities).slice(0, maxCitiesCount)">
                                    {{c}},
                                </template>
                                and {{Object.values(l.cities).length - maxCitiesCount}}
                                {{Object.values(l.cities).length - maxCitiesCount > 1 ? 'cities' : 'city'}}
                            </template>

                            <template v-else>
                                {{Object.values(l.cities).join(", ")}}
                            </template>
                        </template>
                    </v-layout>
                </v-layout>
            </v-layout>
        </v-layout>
    </v-card>
</template>

<script>
    export default {
        name: "request-card",
        props: {
            request: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            maxCitiesCount: 2
        }),
        methods: {
            addCarDetail(key) {
                return this.request.car[key] ? `, ${this.request.car[key]}` : ''
            }
        }
    }
</script>

<style scoped>

</style>