<template>
    <v-layout class="content-layout" column>
        <label class="secondary--text display-1 font-weight-bold">Покупатели</label>
        <users-list/>
    </v-layout>
</template>

<script>
    import UsersList from "../components/customers/UsersList";
    export default {
        name: "UsersView",
        components: {UsersList}
    }
</script>

<style scoped>

</style>