<template>
    <v-layout column>
        <v-layout style="margin-top: 30px;" align-center>
             <v-text-field style="max-width: 250px;"
                           color="secondary"
                           v-model="phone"
                           clearable
                           placeholder="Поиск по номеру телефона"
                           prepend-inner-icon="mdi-magnify"/>

            <v-btn class="secondary"
                   @click="search"
                   style="width: 110px; height: 40px; margin: -10px 0 0 20px; border-radius: 8px;">Найти</v-btn>

            <div class="xls-button" v-ripple @click="saveXls">
                <v-layout align-center justify-center fill-height>
                    <label class="subtitle-1 font-weight-bold">
                        Скачать данные в .xls
                    </label>
                </v-layout>
            </div>
        </v-layout>

        <v-layout>
            <label class="subtitle-1"
                   v-if="!items.length"
                   style="margin-top: 20px;">Покупатели не найдены.</label>

            <label class="subtitle-1"
                   v-else
                   style="margin-top: 20px;">{{usersCountLabel}}</label>
        </v-layout>

        <v-data-table :headers="headers"
                      :items="items"
                      hide-default-footer
                      disable-sort
                      :mobile-breakpoint="200"
                      fixed-header
                      loading-text="Загрузка"
                      :height="height - 290"
                      :items-per-page="perPage"
                      :loading="loading"
                      class="scroll-y"
                      v-if="items.length"
                      style="margin-top: 20px; width: 100%; margin-left: -15px;">
            <template v-slot:item="items">
                <tr>
                    <td class="text-xs-left" style="width: 20%;">
                        <label class="text-truncate">
                            {{items.item.phone}}</label>
                    </td>
                    <td class="text-xs-left" style="width: 20%;">
                        <label class="text-truncate">
                            {{items.item.firstName}}</label>
                    </td>
                    <td class="text-xs-left" style="width: 10%;">
                        <label class="text-truncate">
                            {{items.item.isBlocked ? 'Заблокирован' : 'Активен'}}</label>
                    </td>
                    <td class="text-xs-left" style="width: 25%;">
                        <label class="text-truncate">{{items.item.email}}</label>
                    </td>
                    <td class="text-xs-left" style="width: 15%;">
                        <label class="text-truncate">{{items.item.countryTitle}}</label>
                    </td>
                    <td class="text-xs-left" style="width: 200px;">
                        <label class="text-truncate">{{items.item.createDate | createDate}}</label>
                    </td>
                    <td class="text-xs-right" style="width: 10%;">
                        <v-layout justify-center>
                            <v-btn icon title="Редактировать"
                                   @click="openItem(items.item)">
                                <v-icon color="primery">mdi-information</v-icon>
                            </v-btn>
                        </v-layout>
                    </td>
                </tr>
            </template>
        </v-data-table>

        <v-layout class="text-xs-center pt-2"
                  justify-center
                  align-center
                  style="width: 100%;">
            <v-fade-transition>
                <v-pagination v-model="page"
                              v-if="totalCount"
                              color="secondary"
                              style=" position: absolute; bottom: 15px;"
                              :total-visible="isMobile ? 3 : 7"
                              :length="pagesCount"/>
            </v-fade-transition>
        </v-layout>

        <v-dialog v-model="isVisibleEditModal"
                  :persistent="editLoading"
                  overlay-color="white"
                  overlay-opacity="0.5"
                  width="750">
            <user-form :user="curItem"
                       :is-visible-edit-modal="isVisibleEditModal"
                       :edit-loading="editLoading"
                       @saveItem="saveItem($event)"
                       @close="isVisibleEditModal = false"/>
        </v-dialog>
    </v-layout>
</template>

<script>
    import PaginationMixin from "../../mixins/PaginationMixin";
    import UserForm from "./UserForm";

    export default {
        name: "UsersList",
        components: {UserForm},
        mixins: [PaginationMixin],
        data: () => ({
            headers: [
                {
                    text: 'Номер телефона',
                    align: 'left',
                    sortable: false,
                    value: 'phone',
                },
                {
                    text: 'Имя',
                    align: 'left',
                    sortable: false,
                    value: 'name',
                },
                {
                    text: 'Блокировка',
                    align: 'left',
                    sortable: false,
                    value: 'isBlocked',
                },
                {
                    text: 'Почта',
                    align: 'left',
                    sortable: false,
                    value: 'email',
                },
                {
                    text: 'Страна',
                    align: 'left',
                    sortable: false,
                    value: 'country',
                },
                {
                    text: 'Зарегистрирован',
                    align: 'left',
                    sortable: false,
                    value: 'createDate',
                },
                {
                    text: '',
                    align: 'left',
                    sortable: false,
                    value: 'actions',
                }
            ],
            curItem: {},
            isVisibleEditModal: false,
            editLoading: false,
            phone: ''
        }),
        computed: {
            width() {
                return this.$store.state.width
            },
            height() {
                return this.$store.state.height
            },
            isMobile() {
                return this.$store.state.isMobile
            },
            countTitles() {
                return ['запись', 'записей', 'записи']
            },
            usersCountLabel() {
                const count = this.totalCount;
                let index = 0;
                if (count) {
                    if (count > 9) index = 1;
                    else index = (count % 10 === 1) ? 0 : (count % 10 > 4) ? 1 : 2;
                    return `${count} ${this.countTitles[index]}`
                } else return 'Нет покупателей'
            }
        },
        watch: {
            page(to) {
                const query = to.query;
                this.$router.history.push({name: this.$route.name, query: {...query, page: to}})
            },
            '$route'(to) {
                this.getItems()
            },
        },
        methods: {
            getItems() {
                this.loading = true;
                const params = {
                    headers: {token: localStorage.getItem("token")},
                    params: {page: this.page - 1, limit: this.perPage, searchString: this.phone}
                };
                console.log(params)
                this.$http.get("/customers", params)
                    .then(response => {
                        this.items = response.data.data.customers;
                        this.totalCount = response.data.data.count
                    }).catch(error => {
                        console.log(error)
                    switch (error.response.status) {
                        case 406:
                            // this.$router.history.push({name: 'login'})
                            break;
                        case 401:
                            this.$router.history.push({name: 'login'});
                            break;
                        default:
                            console.log('error');
                            break;
                    }
                }).finally(() => {
                    setTimeout(() => {
                        this.loading = false
                    }, 500)
                })
            },
            openItem(item) {
                this.curItem = item;
                this.isVisibleEditModal = true
            },
            search() {
                const phone = this.phone;
                this.page = 1;
                this.$router.history.push({name: 'users', query: {phone: phone, page: this.page}})
            },
            saveXls() {
                this.xlsLoading = true;
                const params = {
                    headers: {
                        token: localStorage.getItem("token")
                    },
                    responseType: 'blob'
                }
                this.$http.post("/customers/xls", {}, params)
                    .then(response => {
                          const url = window.URL.createObjectURL(new Blob([response.data]));
                          const link = document.createElement('a');
                          link.href = url;
                          link.setAttribute('target', '_blank');
                          link.setAttribute('download', 'Покупатели.xls');
                          link.click();
                    }).catch(error => {
                    console.log(error)
                    switch (error.response.status) {
                        case 406:
                            // this.$router.history.push({name: 'login'})
                            break;
                        case 401:
                            this.$router.history.push({name: 'login'});
                            break;
                        default:
                            console.log('error');
                            break;
                    }
                }).finally(() => {
                    setTimeout(() => {
                        this.xlsLoading = false
                    }, 500)
                })
            },
            saveItem(item) {
                this.items.forEach(v => {
                    if (v.idCustomer === item.idCustomer) {
                        v.phone = item.phone;
                        v.isBlocked = item.isBlocked
                    }
                })
            }
        },
        beforeMount() {
            this.phone = this.$route.query.phone || '';
            this.page = parseInt(this.$route.query.page) || 1
        }
    }
</script>

<style scoped>
    .xls-button {
        margin-left: 200px;
        width: 280px;
        text-align: center;
        height: 50px;
        vertical-align: middle;
        border-radius: 8px;
    }

    .xls-button:hover, .xls-button *:hover {
        cursor: pointer;
    }
</style>